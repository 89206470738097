import React, { Component } from 'react';
import { Link } from 'gatsby';
import { SEO, Logo } from 'components';
import './thank-you.scss';

class FourOhFour extends Component {
  render() {
    return (
      <section className="success-page">
        <SEO title="Page not found - Hudle" />
        <div className="wrapper">
          <Logo />
          <p>Oops, looks like you're lost at sea. Find your raft below...</p>
          <Link to="/" className="button outline-white">
            Go back home
          </Link>
        </div>
      </section>
    );
  }
}

export default FourOhFour;
